<template>
  <v-container>
    <v-form @submit.prevent="saveForm">
      <v-card>
        <draggable v-model="form.sections" group="sections" @start="drag=true" @end="drag=false">
          <v-card v-for="(section, sectionIndex) in form.sections" :key="section.id" class="mb-4">
            <v-card-title v-if="sectionIndex !== 0">
              <v-text-field
                v-model="section.title"
                label="Section Title"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn icon @click="removeSection(sectionIndex)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <draggable v-model="section.questions" group="questions" @start="drag=true" @end="drag=false" :options="{ disabled: sectionIndex === 0 }">
                <v-card v-for="(question, questionIndex) in section.questions" :key="question.id" class="mb-4">
                  <v-card-text>
                    <template v-if="question.type === 'title'">
                      <v-text-field
                        v-model="question.text"
                        label="Form Title"
                        required
                      ></v-text-field>
                    </template>
                    <template v-else-if="question.type === 'description'">
                      <v-textarea
                        v-model="question.text"
                        label="Form Description"
                      ></v-textarea>
                    </template>
                    <template v-else>
                      <v-text-field
                        v-model="question.text"
                        label="Question"
                        required
                      ></v-text-field>
                      <v-select
                        v-model="question.type"
                        :items="questionTypes"
                        label="Question Type"
                        required
                        @change="handleQuestionTypeChange(question)"
                      ></v-select>
                      
                      <!-- Different inputs based on question type -->
                      <div v-if="question.type === 'multiple_choice' || question.type === 'checkbox'">

                        <draggable v-model="question.options" group="options" @start="drag=true" @end="drag=false" handle=".question-handle">


                        <v-toolbar flat v-for="(option, optionIndex) in question.options" :key="optionIndex" align-content="center" align="center">
                             <v-toolbar-items>
                                <v-icon style="cursor:pointer;" class="question-handle">mdi-drag</v-icon>
                             </v-toolbar-items>
                             <v-checkbox dense single-line disabled hide-details=""></v-checkbox>
                             <v-text-field hide-details="" single-line dense
                                    v-model="question.options[optionIndex]"
                                    :label="`Option ${optionIndex + 1}`"
                                ></v-text-field>

                        </v-toolbar> 
                            
                        </draggable>

                     
                    


                        <v-btn @click="addOption(question)" text color="primary">
                          Add Option
                        </v-btn>
                      </div>
                      
                      <div v-if="question.type === 'linear_scale'">
                        <v-slider
                          v-model="question.scaleMax"
                          :min="2"
                          :max="10"
                          label="Scale maximum"
                          thumb-label
                        ></v-slider>
                      </div>
                      
                      <div v-if="question.type === 'file_upload'">
                        <v-select
                          v-model="question.fileTypes"
                          :items="fileTypeOptions"
                          label="Allowed File Types"
                          multiple
                          chips
                        ></v-select>
                      </div>
                      
                   
                    </template>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions v-if="sectionIndex !== 0 || (sectionIndex === 0 && questionIndex > 1)">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="removeQuestion(section, questionIndex)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>

                    <v-divider vertical class="mx-6"></v-divider>

                       <v-switch class="mr-6"
                        v-model="question.required"
                        label="Required"
                      ></v-switch>

                  </v-card-actions>
                </v-card>
              </draggable>
              <v-btn @click="addQuestion(section)" color="primary">
                Add Question
              </v-btn>
            </v-card-text>
          </v-card>
        </draggable>
        
        <v-card-actions>
          <v-btn @click="addSection" color="primary" class="mb-4">
            Add Section
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="previewForm" color="secondary" class="mr-2">
            Preview
          </v-btn>
          <v-btn type="submit" color="primary">Save Form</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <!-- Form Preview Dialog -->
    <v-dialog v-model="previewDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="previewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Form Preview</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <h2>{{ form.sections[0].questions[0].text }}</h2>
          <p>{{ form.sections[0].questions[1].text }}</p>
          <div v-for="(section, index) in form.sections" :key="section.id">
            <h3 v-if="index !== 0">{{ section.title }}</h3>
            <div v-for="(question, qIndex) in section.questions" :key="question.id">
              <template v-if="!(index === 0 && qIndex < 2)">
                <p>{{ question.text }}</p>
                <!-- Render different input types based on question type -->
                <!-- (Implementation omitted for brevity) -->
              </template>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getFirestore, collection, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  data() {
    return {
      form: {
        sections: [{
          id: Date.now(),
          questions: [
            {
              id: Date.now(),
              type: 'title',
              text: '',
            },
            {
              id: Date.now() + 1,
              type: 'description',
              text: '',
            }
          ]
        }]
      },
      questionTypes: [
        'short_answer',
        'long_answer',
        'multiple_choice',
        'checkbox',
        'radio',
        /* 'dropdown',
        'file_upload', */
        'date',
        'time',
/*         'linear_scale'
 */      ],
      fileTypeOptions: ['Images', 'Documents', 'Audio', 'Video'],
      previewDialog: false,
      drag: false
    };
  },
  methods: {
    addSection() {
      this.form.sections.push({
        id: Date.now(),
        title: 'New Section',
        questions: []
      });
    },
    removeSection(index) {
      if (index !== 0) {
        this.form.sections.splice(index, 1);
      }
    },
    addQuestion(section) {
      section.questions.push({
        id: Date.now(),
        text: '',
        type: 'short_answer',
        required: false,
        options: [],
        fileTypes: [],
        scaleMax: 5,
        otherOption: null
      });
    },
    removeQuestion(section, index) {
      if (section !== this.form.sections[0] || index > 1) {
        section.questions.splice(index, 1);
      }
    },
    handleQuestionTypeChange(question) {
      // Reset question-specific properties
      question.options = [];
      question.fileTypes = [];
      question.scaleMax = 5;
    },
    addOption(question) {
      question.options.push('');
    },
    previewForm() {
      this.previewDialog = true;
    },
    async saveForm() {
      try {
        const db = getFirestore();
        if (this.form.id) {
          // Update existing form
          await updateDoc(doc(db, 'forms', this.form.id), this.form);
        } else {
          // Create new form
          const docRef = await addDoc(collection(db, 'forms'), this.form);
          this.form.id = docRef.id;
        }
        console.log('Form saved successfully');
      } catch (error) {
        console.error('Error saving form:', error);
      }
    },
    async deleteForm() {
      if (!this.form.id) return;
      try {
        const db = getFirestore();
        await deleteDoc(doc(db, 'forms', this.form.id));
        console.log('Form deleted successfully');
        // Reset form or navigate away
      } catch (error) {
        console.error('Error deleting form:', error);
      }
    }
  }
};
</script>